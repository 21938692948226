<template>
  <b-form @submit.prevent="onSubmit">
    <b-row>
      <b-col cols="12" sm="5">
        <b-card>
          <h5 class="mb-3">Parameter</h5>
          <b-form-group
            :class="{ row, error: v$.parameter.name.$errors.length }"
            :label="$t('parameter.name')"
            label-for="h-name"
            label-cols-md="4"
            content-cols-lg="8"
          >
            <b-form-input
              v-model="parameter.name"
              @blur="presetSlug(parameter, 'name', 'url')"
              id="h-name"
            />
            <div
              class="input-errors"
              v-for="error of v$.parameter.name.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </b-form-group>

          <b-form-group
            :class="{ row, error: v$.parameter.url.$errors.length }"
            :label="$t('parameter.url')"
            label-for="h-url"
            label-cols-md="4"
            content-cols-lg="8"
          >
            <b-form-input
              v-model="parameter.url"
              @blur="slugifyURL(parameter, 'url')"
              id="h-url"
            />
            <div
              class="input-errors"
              v-for="error of v$.parameter.url.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </b-form-group>

          <b-form-group
            :class="{ row, error: v$.parameter.type.$errors.length }"
            :label="$t('parameter.type')"
            label-for="h-type"
            label-cols-md="4"
            content-cols-lg="8"
          >
            <v-select
              id="h-type"
              :clearable="false"
              v-model="parameter.type"
              :options="types"
            />
            <div
              class="input-errors"
              v-for="error of v$.parameter.type.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </b-form-group>
        </b-card>
        <b-card>
          <b-form-group class="mb-0">
            <label class="label-sm mb-1">{{ $t("parameter.description") }}</label>
            <tinymce
              :content="parameter.description"
              @editorContentChanged="updateContent"
            />
          </b-form-group>
        </b-card>
        <b-card>
          <image-upload
            :preview-image="parameter.fileUrl"
            :label="$t('parameter.featuredImage')"
            @fileUploaded="ilustrationImageChange"
            v-if="parameter['@id']"
            :resource="parameter['@id']"
            file-attribute="FILE"
          />
        </b-card>
      </b-col>

      <b-col cols="12" sm="7">
        <b-card>
          <h5 class="mb-3">{{ $t("parameter.paramterValues") }}</h5>
          <div
            class="parameter-value"
            v-for="(item, index) of parameter.items"
            :key="index"
          >
            <div class="form-group" :class="{ row }">
              <label for="h-name">{{
                  $t("parameter.paramterValueName")
                }}</label>
              <b-form-input
                v-model="item.name"
                @blur="presetSlug(item, 'name', 'value')"
              />
            </div>

            <div class="form-group" :class="{ row }">
              <label for="h-url">{{ $t("parameter.paramterValue") }}</label>
              <b-form-input
                v-model="item.value"
                @blur="slugifyURL(item, 'value')"
              />
            </div>

            <b-button
              variant="secondary"
              class="remove"
              @click="removeParameterValue(index)"
            >
              <bootstrap-icon icon="trash" size="1x"/>
            </b-button>

            <b-button variant="primary" @click="addMeta(item)" class="addMeta">
              + {{ $t("parameter.metaInfo") }}
            </b-button>

            <div class="form-group meta-info">
              <label
                v-if="item.metaInformation && item.metaInformation.length"
                for=""
              >{{ $t("parameter.metaInfo") }}</label
              >
              <div
                v-for="(meta, mindex) of item.metaInformation"
                :key="mindex"
                class="meta-info-value"
              >
                <b-form-input
                  :placeholder="$t('parameter.metaInfoKey')"
                  v-model="item.metaInformation[mindex].key"
                />
                <b-form-input
                  :placeholder="$t('parameter.metaInfoValue')"
                  v-model="item.metaInformation[mindex].value"
                />
                <b-button
                  variant="secondary"
                  @click="removeMeta(item, mindex)"
                  class="removeMeta"
                >
                  <bootstrap-icon icon="x" size="1x"
                  />
                </b-button>
              </div>
            </div>
          </div>
          <b-button
            variant="primary"
            @click="addParameterValue"
          >{{ $t("parameter.addParameterValue") }}
          </b-button
          >
        </b-card>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import useVuelidate from "@vuelidate/core";
import ListUtils from "@/mixins/ListUtils";
import tinymce from "@/components/tinymce.vue";
import ImageUpload from "@/components/ImageUpload.vue";
import ResourceUtils from "@/mixins/ResourceUtils";
import vSelect from "vue-select";

export default {
  components: {
    vSelect,
    ImageUpload,
    tinymce,
  },
  props: {
    parameterObject: {Object, required: true},
    action: {type: String, default: null},
  },
  mixins: [ListUtils, ResourceUtils],
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      parameter: {
        name: {required: this.$translateError('required')},
        url: {required: this.$translateError('required')},
        type: {required: this.$translateError('required')},
      },
    };
  },
  created() {
    this.getEnumList("custom_parameter_type", this.types);
  },
  data() {
    return {
      parameter: this.parameterObject,
      types: [],
    };
  },
  watch: {
    action() {
      this.onSubmit();
    },
  },
  methods: {
    ilustrationImageChange(data) {
      this.parameter.file = data["@id"];
      this.parameter.fileURL = data.url;
    },
    updateContent(content) {
      this.parameter.description = content;
    },
    presetSlug(obj, sourceKey, destKey) {
      if (obj[destKey].length === 0) {
        obj[destKey] = this.$helper.slugifyURL(obj[sourceKey]);
      }
    },
    slugifyURL(obj, key) {
      if (obj[key].length !== 0) {
        obj[key] = this.$helper.slugifyURL(obj[key]);
      }
    },
    removeParameterValue(index) {
      this.parameter.items.splice(index, 1);
    },
    addParameterValue() {
      this.parameter.items.push({
        name: "",
        value: "",
        metaInformation: [],
      });
    },
    addMeta(item) {
      if (!item.metaInformation) {
        item.metaInformation = [];
      }
      item.metaInformation.push({
        key: "",
        value: "",
      });
    },
    removeMeta(item, index) {
      item.metaInformation.splice(index, 1);
    },
    async onSubmit() {
      const isValid = await this.v$.$validate();
      if (isValid) {
        const body = {
          name: this.parameter.name,
          ownerResource: "PRODUCT",
          url: this.parameter.url,
          type: this.parameter.type.id,
          file: this.parameter.file,
          description: this.parameter.description,
          items: [],
        };
        this.parameter.items.forEach((item) => {
          const obj = {
            name: item.name,
            value: item.value,
          };
          if (item.metaInformation && item.metaInformation.length) {
            obj.metaInformation = item.metaInformation;
          }
          body.items.push(obj);
        });

        if (this.action) {
          this.action === "add"
            ? this.create(
              this.$Parameters,
              body,
              this.$t("parameter.created"),
              null,
              this.success,
              this.error
            )
            : this.update(
              this.$Parameters,
              this.parameter.id,
              body,
              this.$t("parameter.updated"),
              null,
              null,
              this.error
            );
        }
      }
      this.$emit("clearAction");
    },
    success() {
      this.$router.push(`/parameters`);
    },
    error() {
      this.$emit("clearAction");
    },
  },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>
